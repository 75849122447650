/**
 * Product Page
 */
import React, { useEffect } from 'react';
import Home from '@/containers/Home';
import Layout from '@/components/Layout';
import DomainExpired from '@/components/DomainExpired';
import { getWebpageTitle } from '@/utils/getWebpageTitle';
import { getMetaDescription } from '@/utils/getMetaDescription';
import { checkForWrongDomain } from '@/utils/checkForWrongDomain';
import { getRequiredCtxProps } from '@/utils/getRequiredCtxProps';
import { useDispatch } from 'react-redux';
import 'url-search-params-polyfill';
import { emptyCart } from '../redux/actions';
import { getSSRStoreData } from '../utils/ssrPageData';
import { useSelector as useReduxSelector } from '../redux';
import { useSetSSRStore, useSSRSelector } from '../redux';
import checkIfThemePreview from '@/utils/checkIfThemePreview';
import { useRouter } from 'next/router';

const Product = (props) => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  useSetSSRStore(props.ssrStore);
  const storeInfo = useSSRSelector((state) => state.storeReducer.store);
  const allProps = {
    ...props,
    storeInfo,
  };

  const reduxStoreData = useReduxSelector((state) => state.storeReducer.store);
  const isPreview = checkIfThemePreview();

  useEffect(() => {
    if (storeInfo?.store_id !== reduxStoreData?.store_id) {
      dispatch(emptyCart());
    }
  }, []);

  if (query.code) {
    return null;
  }

  return (
    <Layout
      store={storeInfo}
      title={storeInfo && getWebpageTitle(storeInfo, props.seo)}
      keywords={props.seo ? props?.seo?.keywords?.toString() : ''}
      Icon={storeInfo?.store_info?.logo_image}
      favIcon={storeInfo?.favicon}
      metaDescription={getMetaDescription(storeInfo, props.seo)}
      OGImage={storeInfo?.store_info?.logo_image}
      isCustomDomain={props.isCustomDomain}
      storeInfo={storeInfo}
      {...allProps}
    >
      {!isPreview && storeInfo?.domain_info?.status === 2 ? (
        <DomainExpired storeInfo={storeInfo} />
      ) : (
        <Home {...allProps} />
      )}
    </Layout>
  );
};

/**
 * @param {import('next').NextPageContext} ctx
 * @returns {any}
 */
export async function getServerSideProps(ctx) {
  if (ctx.query.code) {
    return { props: { isBuilder: true } };
  }
  if (checkForWrongDomain(ctx)) return {};

  const ssrPropData = await getSSRStoreData(ctx, {
    additionalDataToFetch: [
      'theme',
      'collections',
      'categories',
      'themeHtml',
      'seo',
      'coupons',
      'additionalPagesList',
    ],
  });

  const extraProps = getRequiredCtxProps(ctx, ssrPropData);

  return { props: { ...ssrPropData, ...extraProps } };
}

export default Product;
